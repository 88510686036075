import Typography from '@mui/material/Typography';
import {
    Breadcrumbs,
    Card,
    Collapse,
    Grid,
    IconButton,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import axios from "../../services/APIService";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from "@mui/material/Box";
import {ColorProps} from "../../types/extended";
import Dot from "../../components/Dot";
import {useOperation} from "../../contexts/OperationContext";

interface SoilHealthReportType {
    summary: string
    soil_test_results: SoilTestResultType[]
}


interface SoilTestResultType {
    date: string
    analysis: SoilAnalysisType[]
    title: string
    field: string
}

interface ValueRatingType {
    value: number
    rating: string
}

// Using a generic type for the whole object with an index signature
interface SoilAnalysisType {
    [key: string]: string | number | ValueRatingType
}

interface SoilAnalysisType {
    title: string
    score: string
    description: string
}


const AnalysisResult: FC<{ value?: number, rating: string }> = ({value, rating}) => {
    let color: ColorProps;
    let title: string;

    switch (rating) {
        case 'M':
            color = 'warning';
            title = 'Medium';
            break;
        case "VH":
            color = 'success';
            title = 'Very High';
            break;
        case "H":
            color = 'success';
            title = 'High';
            break;
        case "L":
            color = 'error';
            title = 'Low';
            break;
        default:
            color = 'primary';
            title = 'N/A';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color}/>
            {
                value
                    ?
                    <Typography>{value} ({title})</Typography>
                    :
                    <Typography>{title}</Typography>
            }
        </Stack>
    );
}

const SoilInteractionsTable: FC<{ analysis: SoilAnalysisType[] }> = ({analysis}) => {
    return (
        <TableContainer sx={{whiteSpace: 'pre-wrap'}} component={Paper}>
            <Table aria-label="soil analysis table" sx={{whiteSpace: 'pre-wrap'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Interaction</TableCell>
                        <TableCell>Rating</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        analysis.map((value) => {
                            return <TableRow>
                                <TableCell><Typography sx={{fontWeight: 'bold'}}>{value.title}</Typography></TableCell>
                                <TableCell><AnalysisResult rating={value.score}/></TableCell>
                                <TableCell>{value.description}</TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const SoilTestRow: FC<SoilTestResultType> = ({date, analysis, title, field}) => {
    const [open, setOpen] = useState(false);
    const orderedSoilInteractions = analysis.sort((a, b) => ["L", "M", "H", "VH"].indexOf(a.score) < ["L", "M", "H", "VH"].indexOf(b.score) ? -1 : 1)

    return <>
        <TableRow>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>

            <TableCell key={1}>{title}</TableCell>
            <TableCell key={2}>{field}</TableCell>
            <TableCell key={3}>{date}</TableCell>
        </TableRow>
        <TableRow>
            {/*<TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={4}>*/}
            <TableCell style={{padding: 0}} colSpan={4}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <SoilInteractionsTable analysis={orderedSoilInteractions}/>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const SoilHealth: FC = () => {
        const [report, setReport] = useState<SoilHealthReportType | null>(null)
        const {operation} = useOperation()

        useEffect(() => {
            if (operation) {
                const params = {operation_id: operation.id}
                axios.get('/soil-health/soil-health-report/', {params})
                    .then(reportInfo => {
                        setReport(reportInfo.data);
                    })
            }
        }, [operation])

        return (

            report ?
                <>
                    <Grid container spacing={2.5}>
                        <Grid item width={'100%'}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                                    Full Survey Report
                                </Link>
                                <Typography color="textPrimary">
                                    Soil Health
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <DashboardTitle title={"Soil Health"}/>
                        <TextBaseCard title={"Summary"}>
                            <Markdown>
                                {report?.summary}
                            </Markdown>
                        </TextBaseCard>
                        <Grid item width={'100%'}>
                            <Grid item sx={{paddingBottom: "12px"}}>
                                <Typography variant="h5">Tests</Typography>
                            </Grid>
                        </Grid>
                        <Grid item width={'100%'}>
                            {/* TODO: Fix map for soil tests and reactivate */}
                            {/*<Grid item width={'50%'}>*/}
                            <Card
                                sx={{
                                    width: '100%',
                                    background: '#FFFFFF'
                                }}
                            >
                                <TableContainer
                                    sx={{
                                        width: '100%',
                                        overflowX: 'auto',
                                        position: 'relative',
                                        display: 'block',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <Table aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell key={1}>Name</TableCell>
                                                <TableCell key={2}>Field</TableCell>
                                                <TableCell key={3}>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                report.soil_test_results.map((result, index) => {
                                                    return <SoilTestRow
                                                        date={result.date}
                                                        analysis={result.analysis}
                                                        title={`Baseline Test (T${index + 1})`}
                                                        // TODO: DEMO: Fix these hard coded values
                                                        field={index > 3 ? "Stucky Front" : "Stucky Rear"}
                                                    />
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                        {/*<Grid item width={'50%'}>*/}
                        {/*    <Card>*/}
                        {/*        <Map></Map>*/}
                        {/*    </Card>*/}
                        {/*</Grid>*/}
                    </Grid>
                </>
                : <></>
        );
    }
;

export default SoilHealth;