import Typography from '@mui/material/Typography';
import {
    Alert, AlertTitle,
    Box,
    Breadcrumbs,
    Collapse,
    Grid,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {BaseCard} from "../../../components/BaseCard";
import {useOperation} from "../../../contexts/OperationContext";
import axios from "../../../services/APIService";
import Button from "@mui/material/Button";
import Markdown from "react-markdown";
import {convertSnakeToTitleCase, formatDateAsMonthDay} from "../../../utils/formatting";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import {Grass, QueryStats, SvgIconComponent, SwipeRightAlt} from "@mui/icons-material";
import Dot from "../../../components/Dot";
import {ColorProps} from "../../../types/extended";
import {InfoCircleFilled} from "@ant-design/icons";

interface DailyTask {
    title: string
    description: string
    type: DailyTaskType
    location: string
    status: DailyTaskStatusType
    notes: string
    date: string
}

// const StateTableRow: FC<{ date: string, subDivisionStates: SubDivisionState[] }> = ({date, subDivisionStates}) => {
//     const rowDate = new Date(date)
//     const dateString = displayFormattedDate(rowDate);
//     return <TableRow>
//         <TableCell key='date'>{dateString}</TableCell>
//         {
//             subDivisionStates.map((subDivisionState, index) => {
//                 let stateText = "";
//                 if (subDivisionState.state_type === "GRAZING") {
//                     const grazingUnits = subDivisionState.last_grazed.filter((lastGrazedDate) => {
//                         return lastGrazedDate === stringFormattedDate(rowDate)
//                     }).length
//                     stateText = `Grazing (${grazingUnits / 10} acres)`
//                     if (grazingUnits === 0) {
//                         console.log(subDivisionState.last_grazed)
//                     }
//
//                 } else {
//                     stateText = subDivisionState.state_type
//                 }
//                 const warningEl = subDivisionState.warning ?
//                     <Tooltip
//                         title={subDivisionState.warning}>
//                         <IconButton>
//                             <Info/>
//                         </IconButton>
//                     </Tooltip> :
//                     <></>
//
//
//                 ;
//                 return <TableCell key={index}>{stateText}{warningEl}</TableCell>
//             })
//         }
//     </TableRow>
// }
//

interface TodayTODORowType {
    type: string,
    title: string,
    description: string,
    notes: string,
    location: string,
    status: DailyTaskStatusType
}

type DailyTaskStatusType = "SCHEDULED" | "COMPLETED_UNCHANGED" | "COMPLETED_WITH_CHANGE" | "NOT_DONE" | "POSTPONED"
type DailyTaskType = "PLANTING" | "CATTLE_MOVE" | "MEASURE_GROWTH"

const STATUS_COLOR_MAP = {
    "SCHEDULED": "warning",
    "COMPLETED_UNCHANGED": "success",
    "COMPLETED_WITH_CHANGE": "success",
    "NOT_DONE": "error",
    "POSTPONED": "error",
}

const STATUS_TEXT_MAP = {
    "SCHEDULED": "Scheduled",
    "COMPLETED_UNCHANGED": "Completed",
    "COMPLETED_WITH_CHANGE": "Completed",
    "NOT_DONE": "Not Done",
    "POSTPONED": "Postponed",
}

const TASK_TYPE_TO_ICON_MAP: { [key in DailyTaskType]: SvgIconComponent } = {
    "PLANTING": Grass,
    "CATTLE_MOVE": SwipeRightAlt,
    "MEASURE_GROWTH": QueryStats,
}

const TodayTODORow: FC<TodayTODORowType> = ({type, title, description, location, notes, status}) => {
    const [open, setOpen] = useState(false);
    const statusColor = STATUS_COLOR_MAP[status]
    const statusText = STATUS_TEXT_MAP[status]

    return <>
        <TableRow>
            <TableCell key={2}>{title}</TableCell>
            <TableCell key={3}>{type}</TableCell>
            <TableCell key={4}>{location}</TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Dot color={statusColor as ColorProps}/>
                    <Typography>{statusText}</Typography>
                </Stack>
            </TableCell>
            <TableCell key={5}>
                <Button
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                >
                    {open ? "Hide Details" : "Show Details"}
                </Button>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{padding: 0}} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box style={{padding: "5px 20px"}}>
                        {
                            description
                                ?
                                <Box style={{padding: "10px 0"}}>
                                    <Typography component={"span"} fontWeight={"900"}>Details: </Typography>
                                    <Typography component={"span"}><Markdown>{description}</Markdown></Typography>
                                </Box>
                                :
                                <></>
                        }
                        {
                            status.slice(0, 9) !== "COMPLETED" && !notes
                                ?
                                <Box style={{padding: "10px 0"}}>
                                    <Button color={"success"} style={{margin: 5}}>Completed</Button>
                                    <Button color={"warning"} style={{margin: 5}}>Log Issue</Button>
                                </Box>
                                :
                                <></>
                        }
                        {
                            notes.trim().length > 0
                                ?
                                <Box style={{padding: "10px 0"}}>
                                    <Typography component={"span"} fontWeight={"900"}>Notes: </Typography>
                                    <Typography component={"span"}>{notes}</Typography>
                                </Box>
                                :
                                <></>
                        }
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const FutureTasksTimeline: FC<{ tasks: DailyTask[] }> = ({tasks}) => {
    // Group tasks by date
    const groupedFutureTasks = tasks.reduce((acc: { [key: string]: DailyTask[] }, item) => {
        const date = item.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(item);
        return acc;
    }, {});
    // Order by date
    const orderedGroupedFutureTasks = Object.keys(groupedFutureTasks)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map(date => ({
            date,
            items: groupedFutureTasks[date],
        }));

    return (
        <Timeline
            sx={{
                '& .MuiTimelineItem-root': {minHeight: '40px'},
                '& .MuiTimelineOppositeContent-root': {flex: 0},
                '& .MuiTimelineDot-root': {
                    borderRadius: 1.25,
                    boxShadow: 'none',
                    margin: 0,
                    ml: 1.25,
                    mr: 1.25,
                    p: 1,
                    '& .MuiSvgIcon-root': {fontSize: '1.2rem'}
                },
                '& .MuiTimelineContent-root': {
                    borderRadius: 1,
                    bgcolor: 'secondary.lighter',
                    height: '100%'
                },
                '& .MuiTimelineConnector-root': {
                    border: '1px dashed',
                    borderColor: 'secondary.light',
                    bgcolor: 'transparent'
                }
            }}
        >
            {
                orderedGroupedFutureTasks.map((taskGroup, i) => {
                    const today = new Date();
                    const nextDate = new Date(today);
                    nextDate.setDate(today.getDate() + i + 1);


                    return (
                        taskGroup.items.map((task, innerI) => {
                            const TaskIcon = TASK_TYPE_TO_ICON_MAP[task.type]
                            const statusColor = STATUS_COLOR_MAP[task.status]
                            const isInitialTaskForDay = innerI === 0
                            const isFinalTaskForDay = innerI === taskGroup.items.length - 1
                            return <TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                    <Box sx={{width: "40px"}}>
                                        {
                                            isInitialTaskForDay
                                                ?
                                                formatDateAsMonthDay(new Date(taskGroup.date))
                                                :
                                                <></>
                                        }
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot sx={{
                                        color: `${statusColor}.main`,
                                        bgcolor: `${statusColor}.lighter`,
                                    }}>
                                        <TaskIcon style={{fontSize: '1.3rem'}}/>
                                    </TimelineDot>
                                    <TimelineConnector
                                        sx={{height: isFinalTaskForDay ? "40px" : "10px"}}/>
                                </TimelineSeparator>
                                <TimelineContent sx={{mb: "3px"}}>
                                    <Typography component={"span"}>
                                        {task.title}{task.status === "POSTPONED" ? " (Postponed)" : ""}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        })
                    )
                })
            }

        </Timeline>
    )
}

const UpdatedPlansAlert = () =>{
    return <Alert color="success" variant="border" icon={<InfoCircleFilled />}>
        <AlertTitle><Typography variant="h5">Projected hay costs decreased</Typography></AlertTitle>
  <Typography variant="h6">Increased pasture growth in Stucky West (S4) expected to delay start of hay feeding to 12/15.</Typography>
</Alert>
}


// const transformSimulationStatesToForageAvailability = (subDivisionStates: SubDivisionState[]): SubDivisionForageAmountsWithDate[] => {
//     // First group data in object, keyed by date
//     const availabileForageByDate = subDivisionStates.reduce((acc: SubDivisionForageAmountsByDate, state: SubDivisionState) => {
//         if (!acc[state.date]) {
//             acc[state.date] = {};
//         }
//         acc[state.date][state.sub_division.identifier] = state.forage_amounts.reduce((sum, current) => sum + parseFloat(current), 0)
//         return acc;
//     }, {} as SubDivisionForageAmountsByDate);
//
//     // Move date into each object
//     const unorderedForageAvailabilityData: SubDivisionForageAmountsWithDate[] = Object.entries(availabileForageByDate).map(([date, forageAmounts]) => {
//         return {
//             date: date,
//             ...forageAmounts
//         } as SubDivisionForageAmountsWithDate
//     })
//
//     // Sort by date
//     return unorderedForageAvailabilityData.sort((a, b) => {
//         const aDate = new Date(a.date as string)
//         const bDate = new Date(b.date as string)
//         return aDate.getTime() - bDate.getTime()
//     })
// }


const PlanningOperations = () => {
    // const [planningSummary, setPlanningSummary] = useState<string | null>(null)
    // const [planningActions, setPlanningActions] = useState<PlanningAction[]>([])
    // const [statesGroupedByDate, setStatesGroupedByDate] = useState<SubDivisionStatesByDate>({})
    // const [forageData, setForageData] = useState<SubDivisionForageAmountsWithDate[]>([])
    const [todayTasks, setTodayTasks] = useState<DailyTask[]>([])
    const [futureTasks, setFutureTasks] = useState<DailyTask[]>([])
    const {operation} = useOperation()

    const isToday = (date: Date) => {
        const today = new Date();
        return (
            today.getFullYear() === date.getUTCFullYear()
            &&
            today.getMonth() === date.getUTCMonth()
            &&
            today.getDate() === date.getUTCDate()
        )
    }

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/planning/daily-tasks/', {params})
                .then(response => {
                    const _todayTasks: DailyTask[] = [];
                    const _futureTasks: DailyTask[] = [];

                    response.data.daily_tasks.map((task: DailyTask) => {
                        const taskDate = new Date(task.date);
                        if (isToday(taskDate)) {
                            _todayTasks.push(task)
                        } else {
                            _futureTasks.push(task)
                        }
                    })
                    setTodayTasks(_todayTasks);
                    setFutureTasks(_futureTasks)
                })
            axios.get('/planning/summary/', {params})
                .then(response => {
                    // setPlanningSummary(response.data.summary)


                    // setPlanningActions(
                    //     response.data.actions.map((action: PlanningAction) => ({
                    //         ...action,
                    //         startDate: new Date(action.start_date),
                    //         endDate: new Date(action.end_date)
                    //     })).sort((a: PlanningAction, b: PlanningAction) => a.startDate.getTime() - b.startDate.getTime())
                    // )
                    //
                    // setStatesGroupedByDate(
                    //     response.data.simulation_results.reduce((acc: SubDivisionStatesByDate, state: SubDivisionState) => {
                    //         if (!acc[state.date]) {
                    //             acc[state.date] = [];
                    //         }
                    //         acc[state.date].push(state);
                    //         return acc;
                    //     }, {} as { [key: string]: SubDivisionState[] })
                    // )
                    //
                    // setForageData(
                    //     transformSimulationStatesToForageAvailability(response.data.simulation_results)
                    // )
                })
        }
    }, [operation]);

//     const summary = `
// **Estimated time in field:** 5 hours
//
// Today's activities should start with the regular morning move of cattle.
//
// This will be followed by planting interseeded legumes into the grasses in Stucky Back (S1). The seed mixes have been prepared and equipment is ready to go.`



    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                            Planning
                        </Link>
                        <Typography color="textPrimary">
                            Co-pilot
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Co-pilot"}/>
                {/*<Grid item width={'100%'}>*/}
                {/*    <Alert severity="warning"><Typography fontWeight={"700"} component={"span"}>Heavy rain is forecast*/}
                {/*        for 3 days time:</Typography> Warm season annual planting*/}
                {/*        schedule will be moved back by 5 days.</Alert>*/}
                {/*</Grid>*/}
                <Grid item width={'100%'}>
                    <BaseCard title={"Today"}>
                        {/*<Box*/}
                        {/*    sx={{p: '24px'}}*/}
                        {/*>*/}
                        {/*    <Markdown>*/}
                        {/*        {summary}*/}
                        {/*    </Markdown>*/}
                        {/*</Box>*/}
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={2}>Activity</TableCell>
                                        <TableCell key={3}>Type</TableCell>
                                        <TableCell key={4}>Location</TableCell>
                                        <TableCell key={5}>Status</TableCell>
                                        <TableCell key={6}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        todayTasks.map((dailyTask: DailyTask, index) => {
                                            return <TodayTODORow key={index}
                                                                 description={dailyTask.description}
                                                                 title={dailyTask.title}
                                                                 type={convertSnakeToTitleCase(dailyTask.type)}
                                                                 status={dailyTask.status}
                                                                 location={dailyTask.location}
                                                                 notes={dailyTask.notes}
                                            />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
                {
                    todayTasks.filter((task) => task.status.slice(0, 9) === 'COMPLETED').length > 0
                    ?
                        <Grid item width={'100%'}>
                            <UpdatedPlansAlert/>
                        </Grid>
                        :
                        <></>
                }
                <Grid item width={'100%'}>
                    <BaseCard title={"Upcoming"}>
                        <FutureTasksTimeline tasks={futureTasks}/>
                    </BaseCard>
                </Grid>
                {/*<DashboardTitle title={"Operations"}/>*/}
                {/*/!*<Grid item width={'100%'}>*!/*/}
                {/*/!*    <TextBaseCard title={"Summary"}>*!/*/}
                {/*/!*        <Markdown>*!/*/}
                {/*/!*            {planningSummary}*!/*/}
                {/*/!*        </Markdown>*!/*/}
                {/*/!*    </TextBaseCard>*!/*/}
                {/*/!*</Grid>*!/*/}
                {/*<Grid item width={'100%'}>*/}
                {/*    <BaseCard title={"Activities"}>*/}
                {/*        <PlanningActionTable planningActions={planningActions}/>*/}
                {/*    </BaseCard>*/}
                {/*</Grid>*/}

                {/*<Grid item width={'100%'}>*/}
                {/*    <BaseCard title={"Grazing Projections"}>*/}
                {/*        <Box style={{width: '100%', height: '500px'}}>*/}
                {/*            <GrazingProjectionsChart planningActions={planningActions} forageData={forageData}/>*/}
                {/*        </Box>*/}
                {/*    </BaseCard>*/}
                {/*</Grid>*/}

                {/*<Grid item width={'100%'}>*/}
                {/*    <BaseCard title={"Day by day"}>*/}
                {/*        <TableContainer*/}
                {/*            sx={{*/}
                {/*                width: '100%',*/}
                {/*                overflowX: 'auto',*/}
                {/*                position: 'relative',*/}
                {/*                display: 'block',*/}
                {/*                maxWidth: '100%',*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <Table aria-labelledby="tableTitle" sx={{*/}
                {/*                maxWidth: '100%',*/}
                {/*                tableLayout: "auto",*/}
                {/*                // width: "auto"*/}
                {/*            }}>*/}
                {/*                <TableHead>*/}
                {/*                    <TableRow>*/}
                {/*                        <TableCell key={1}></TableCell>*/}
                {/*                        <TableCell key={2}>Date</TableCell>*/}
                {/*                        <TableCell key={4}>Location</TableCell>*/}
                {/*                        <TableCell key={5}>Status</TableCell>*/}
                {/*                    </TableRow>*/}
                {/*                </TableHead>*/}
                {/*                <TableBody>*/}
                {/*                    <>*/}
                {/*                        {*/}
                {/*                            Object.entries(statesGroupedByDate).map(([date, subDivisionStates], index) => {*/}
                {/*                                return <StateTableRow key={index} date={date}*/}
                {/*                                                      subDivisionStates={subDivisionStates}/>*/}
                {/*                            })*/}
                {/*                        }*/}
                {/*                    </>*/}
                {/*                </TableBody>*/}
                {/*            </Table>*/}
                {/*        </TableContainer>*/}
                {/*    </BaseCard>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    )
}

export default PlanningOperations