import {Card, CardHeader, Divider, Grid, SxProps} from '@mui/material';
import Box from '@mui/material/Box';
import {FC} from 'react';

interface TextCardWithChildrenProps {
    title: string;
    children?: React.ReactNode;
    secondary?: React.ReactNode;
    sx?: SxProps;
}

export const BaseCard: FC<TextCardWithChildrenProps> = ({
                                                            title, children, secondary, sx
                                                        }) => {
    return (
        <Grid item width={'100%'}>
            <Card
                sx={{
                    width: '100%',
                    background: '#FFFFFF',
                    ...sx
                }}
            >
                <CardHeader title={title} sx={{p: '24px'}} action={secondary}/>
                <Divider/>
                {children}
            </Card>
        </Grid>
    )
        ;
};


export const TextBaseCard: FC<TextCardWithChildrenProps> = ({title, children}) => {
    return <BaseCard title={title}>
        <Box
            sx={{p: '24px'}}
        >
            {children}
        </Box>
    </BaseCard>
}