import React, {ReactNode} from "react";
import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box";
import AdminFooter from "./AdminFooter";

interface AdminWrapperProps {
    children: ReactNode;
}

const AdminWrapper: React.FC<AdminWrapperProps> = ({children}) => {
    const isAdmin = true;
    if (isAdmin) {
        return (
            <>
                <Container>
                    <Box sx={{minHeight: '100vh', pb: 8}}> {/* Add padding-bottom to avoid content overlap */}
                        { children }
                    </Box>
                </Container>
                <AdminFooter/>
            </>
        )
    }
    return <>{children}</>
}

export default AdminWrapper