import Typography from '@mui/material/Typography';
import {Breadcrumbs, Grid, Link} from '@mui/material';
import {FC, useEffect} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {BaseCard, TextBaseCard} from "../../../components/BaseCard";
import {useOperation} from "../../../contexts/OperationContext";
import axios from "../../../services/APIService";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import Markdown from "react-markdown";

const PlanningLongTermTimelineItem: FC<{year: string, headline: string, content: string}> = ({year, headline, content}) => {
    const headlineSplit = headline.split(":")

    return <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
            {year}
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot color="primary"/>
            <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant={"h5"} sx={{ fontStyle: 'italic' }} component={"span"}>
                {headlineSplit[0]}:
            </Typography>
            <Typography variant={"h5"} sx={{ fontWeight: '500' }} component={"span"}>
                {headlineSplit[1]}
            </Typography>
            <Markdown>
                {content}
            </Markdown>
        </TimelineContent>
    </TimelineItem>
}


const PlanningLongTermTimeline = () => {
    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
        >
            <PlanningLongTermTimelineItem
                year={'2024'}
                headline={'Decrease costs and establish new system: Reduce hay costs and prepare for improved grazing and pasture'}
                content={`**Pasture Seeding:** Interseed cover crops to enable grazing later into winter, reducing hay needs from 4 to 3 tons per AU
                       
**Neutralize Endophyte Impact:** Diversify pasture to decrease impact of endophyte infection on calving and weaning rates in summer months

**Daily Moves:** Start moving cattle daily to keep fertility in pasture, reducing need for fertilizer

**Improve Water Infrastructure**: Add piping to enable daily moves`}
                />
            <PlanningLongTermTimelineItem
                year={'2025'}
                headline={'Reach Break-even: Weaning rates rate increasing calf sale revenues, increasing herd size, while further decreasing to hay costs.'}
                content={`**Increase Herd Size:** Add 5 cows (18 to 23) due to increased carrying capacity from more productive pasture with cover crops and use of annuals
                       
**Increased Weaning Rates:** Less summer grazing in infected fescue increases cow calving rates 

**Lower hay costs:** Stockpiling fescue will provide feed further through winter, lowering reliance on hay  

**Annual Pasture:** Continue with summer and winter annuals in preparation for planting warm-season grasses`}
            />
            <PlanningLongTermTimelineItem
                year={'2026'}
                headline={'Achieve profitability: Increase weaning rate to 90%, calf sale weights from 600 to 650lbs, and herd size by 22% while further decrease hay costs'}
                content={`**Increase Herd Size:** Add 5 cows (23 to 28) due to increased productivity
                
**Increased Sale Weights:** More nutritious pasture will allow more gan per animal and therefore higher sale weights (from 600lbs to 650lbs), increasing income.

**Maximize Weaning Rates:** Interseeded legumes will counteract fescue issues, removing all pasture-based limitations to calving productivity, taking rates to 90%+.

**Decrease Hay Costs:** Further decreases in hay needs from 2 to 1 tons/AU will continue cost decreases.
                `}
            />
            <PlanningLongTermTimelineItem
                year={'2027'}
                headline={'Grow Profits: Increased pasture productivity allows larger herd and higher sale weights with lower hay needs'}
                content={`**Increase Herd Size:** Increase from 23 to 28
                `}
            />
        </Timeline>
    );
}

const PlanningOverview = () => {
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/planning/operations', {params})
                .then(response => {
                })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                            Planning
                        </Link>
                        <Typography color="textPrimary">
                            Overview
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Overview"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Top Opportunities"}>
                        <Grid container width={"100%"}>
                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `## Calving Rates
   ### Increase revenue per cow by 5x 
   - Current calving rate <20%.
   - Severely below potential (>90%)
   - **Can increase by addressing endophyte infection in fescue**
   `}
                                </Markdown>

                            </Grid>
                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `## Hay Costs
   ### Save >$300 costs per cow
   - Currently 4 tons/cow
   - Majority of costs
   - Can lower to 0.5 tons/cow as backup
   - **Address by extending productive grazing season through diversity of pasture types**
   `}
                                </Markdown>

                            </Grid>
                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `## Pasture Productivity
   ### Increase stocking rates by 4x
   - Monoculture endophyte infected fescue limits productive season
   - Weed pressure limits stocking rates
   - **Achieve with year-long grazing from diversity and focused management**
   `}
                                </Markdown>

                            </Grid>
                        </Grid>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Strategy"}>
                        <Markdown>{`Enhancing fertility and forage management will significantly boost pasture productivity, calving rates, and herd gains, leading to increased farm profitability. By ensuring even nutrient distribution and leveraging soil biology, we reduce the need for synthetic fertilizers and lower operational costs. Seasonal forage rotation and strategic grazing practices extend forage availability, optimizing livestock health and minimizing feed expenses.`}</Markdown>
                        <Markdown>
                            {`## Fertility Management`}
                        </Markdown>
                        <Grid container width={"100%"}>
                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `#### Increase Field Fertility
   - Boost with initial NPK fertilizer.
   - Maintain fertility through management.`}
                                </Markdown>

                            </Grid>

                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `#### Minimize Fertility Removal
   - Use manure to return nutrients to the soil.
   - Implement daily moves to ensure even manure distribution.
   - Use tools like batt latch openers for minimal labor.`}
                                </Markdown>

                            </Grid>

                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    ` #### Enhance Soil Biology
   - Apply soil microbes to replenish nutrients.
   - Inter-seed legumes and forbs to improve soil health.
   - Maintain soil microbes by keeping roots in the ground and preserving soil structure.`}
                                </Markdown>

                            </Grid>
                        </Grid>

                        <Markdown>
                            {`## Forage Management`}
                        </Markdown>
                        <Grid container width={"100%"}>
                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `#### Diversify Forage
   - Introduce diverse legumes pasture to increase nitrogen fixation and forage yield
   - Negate impact of endophyte infection on cows to increase calving and weaning rates
   - Add resilience to different weather patterns`}
                                </Markdown>

                            </Grid>

                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `#### Extend Forage Availability
   - Rotate livestock through different pastures year-round.
   - Use diverse range of pastures to provide forage in all seasons
   - Ensure there is productive pasture at all time
     `}
                                </Markdown>

                            </Grid>


                            <Grid item width={"33.3%"}>
                                <Markdown>{
                                    `  #### Winter Grazing
   - Use pasture like fescue into winter to reduce hay costs.
   - Develop water infrastructure for freezing temperatures.`}
                                </Markdown>

                            </Grid>
                        </Grid>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <BaseCard title={"Timeline"}>
                        <PlanningLongTermTimeline/>
                    </BaseCard>
                </Grid>
            </Grid>
        </>
    )
}

export default PlanningOverview