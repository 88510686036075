const Paths = {
  INDEX: '/',
  HOME: '/home',
  ADMIN: {
    INDEX: '/admin',
    CUSTOMERS: '/admin/customers',
    OPERATIONS: '/admin/operations',
    OPERATORS: '/admin/operators',
    MARKETS: '/admin/markets',
    FUNDING: '/admin/funding',
    TRAINING: '/admin/training',
    // : '/admin/funding',
  },
  DASHBOARD: '/dashboard',
  CONTEXT_REPORT: '/dashboard/context-report',
  FULL_SURVEY: {
    INDEX: '/dashboard/full-survey',
    OVERVIEW: '/dashboard/full-survey/overview',
    SOIL_HEALTH: '/dashboard/full-survey/soil-health',
    INFRASTRUCTURE: '/dashboard/full-survey/infrastructure',
    WEATHER_CLIMATE: '/dashboard/full-survey/weather-climate',
    MARKETS: '/dashboard/full-survey/markets',
    OPERATORS: '/dashboard/full-survey/operators',
    FINANCES: '/dashboard/full-survey/finances',
    FUNDING: '/dashboard/full-survey/funding',
    ENTERPRISES: '/dashboard/full-survey/enterprises',
    PARTNERS: '/dashboard/full-survey/partners',
  },
  PLANNING: {
    INDEX: '/dashboard/planning',
    OVERVIEW: '/dashboard/planning/overview',
    TODAY: '/dashboard/planning/today',
    OPERATIONS: '/dashboard/planning/operations',
    FINANCES: '/dashboard/planning/finances',
  }
};

export default Paths