import Header from './Header';
import NavDrawer from './NavDrawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import {Outlet} from 'react-router-dom';
import {FC} from "react";


const Dashboard: FC = () => {

        // Whenever we re-enter the Dashboard we should refresh Customer data

        // useEffect(() => {
        //         if (currentUser) {
        //             // Fetch customer data based on the user's information
        //             console.log("User logged in:", currentUser.email);
        //             // Assume fetchCustomerDetails is a function that fetches customer details from an API
        //             getCustomer()
        //                 .then(newCustomer => {
        //                     if (operation) {
        //                         console.log("Operation set:", operation)
        //                     } else if (newCustomer && !operation && newCustomer.operations.length > 0) {
        //                         saveOperation(newCustomer.operations[0]);
        //                     } else {
        //                         console.error('Error fetching operation')
        //                         saveOperation(null);
        //                     }
        //                 })
        //                 .catch(error => {
        //                     console.error('Error fetching customer info:', error);
        //                     saveOperation(null);  // Reset operation on error
        //                 });
        //         } else {
        //             // If no user is logged in, clear the customer data
        //             console.log("No user logged in");
        //             setCustomer(null);
        //         }
        //     }, [currentUser]
        // );

        return (
            <>
                <Box sx={{display: 'flex', width: '100%'}}>
                    <Header/>
                    <NavDrawer/>
                    <Box
                        component={'main'}
                        sx={{
                            width: '100%',
                            padding: '24px'
                        }}
                    >
                        <Toolbar/>
                        <Outlet/>
                    </Box>
                </Box>
            </>
        )
            ;
    }
;

export default Dashboard;