import {
    Alert,
    Breadcrumbs,
    Checkbox,
    Collapse,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import Typography from "@mui/material/Typography";
import DashboardTitle from "../../../components/DashboardTitle";
import {BaseCard, TextBaseCard} from "../../../components/BaseCard";
import Markdown from "react-markdown";
import {FC, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface TodayTODORowType {
    done: boolean,
    type: string,
    title: string,
    details: string,
    location: string,
}

const TodayTODORow: FC<TodayTODORowType> = ({done, type, title, details, location}) => {
    const [open, setOpen] = useState(false);

    return <>
        <TableRow>
            <TableCell key={1}><Checkbox/></TableCell>
            <TableCell key={2}>{title}</TableCell>
            <TableCell key={3}>{type}</TableCell>
            <TableCell key={4}>{location}</TableCell>
            <TableCell key={5}>
                <Button
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                >
                    {open ? "Hide Details" : "Show Details"}
                </Button>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{padding: 0}} colSpan={4}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box style={{padding: "5px 20px"}}>
                        <Markdown>{details}</Markdown>
                        <Button color={"success"} style={{margin: 5}}>Completed</Button>
                        <Button color={"warning"} style={{margin: 5}}>Log Issue</Button>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const PlanningToday: FC = () => {
    const summary = `
**Estimated time in field:** 5 hours

Today's activities should start with the regular morning move of cattle.  

This will be followed by planting interseeded legumes into the grasses in P1 (Stucky Rear 40). The seed mixes have been prepared and equipment is ready to go.`
    return <>
        <Grid container spacing={2.5}>
            <Grid item width={'100%'}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                        Planning
                    </Link>
                    <Typography color="textPrimary">
                        Today
                    </Typography>
                </Breadcrumbs>
            </Grid>
            <DashboardTitle title={"Today"}/>
            {/*<Grid item width={'100%'}>*/}
            {/*    <Button variant="outlined">Trigger Call</Button>*/}
            {/*</Grid>*/}
            <Grid item width={'100%'}>
                <Alert severity="warning"><Typography fontWeight={"700"} component={"span"}>Heavy rain is forecast for 3 days time:</Typography> Warm season annual planting
                    schedule will be moved back by 5 days.</Alert>
            </Grid>
            <Grid item width={'100%'}>
                <TextBaseCard title={"Overview"}>
                    <Markdown>
                        {summary}
                    </Markdown>
                </TextBaseCard>
            </Grid>
            <Grid item width={'100%'}>
                <BaseCard title={"TODOs"}>
                    <TableContainer
                        sx={{
                            width: '100%',
                            overflowX: 'auto',
                            position: 'relative',
                            display: 'block',
                            maxWidth: '100%',
                        }}
                    >
                        <Table aria-labelledby="tableTitle" sx={{
                            maxWidth: '100%',
                            tableLayout: "auto",
                            // width: "auto"
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell key={1}>Done</TableCell>
                                    <TableCell key={2}>Activity</TableCell>
                                    <TableCell key={3}>Type</TableCell>
                                    <TableCell key={4}>Location</TableCell>
                                    <TableCell key={5}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TodayTODORow
                                    details={"60 lbs / ac with no-till drill"}
                                    title={"Interseed Legumes"}
                                    type={"Planting"}
                                    done={false}
                                    location={"P1 (Stucky Rear 40)"}
                                />
                                <TodayTODORow
                                    details={"Move herd into new 1.25 acre paddock. Previous paddock should leave pasture longer than 6 inches. **Add note if not.**"}
                                    title={"Daily Cattle Move"}
                                    type={"Cattle Move"}
                                    done={false}
                                    location={"P2 (Stucky Front 20)"}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BaseCard>
            </Grid>
        </Grid>
    </>
}

export default PlanningToday